import React from 'react';
import { PulseLoader } from 'react-spinners';
import './appointment_options.scss';
import styled from 'styled-components';
import moment from 'moment';
import { getContrast } from '../../styles/contrast';

class AppointmentOptionsOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loadingTimes: false,
      hover: JSON.parse(window.sessionStorage.getItem("phoneType")),
      appointments: []
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleAppointment = this.handleAppointment.bind(this);
    this.handleProvider = this.handleProvider.bind(this);
  }

  scrollToTop() {
    const scrolly = document.getElementsByClassName("div2");
    if (JSON.parse(window.sessionStorage.phoneType)) scrolly[0].scrollIntoView(true);
  };

  componentDidMount() {
    this.scrollToTop();

    const currentPage = window.sessionStorage.getItem("currentPage");
    this.setState({ loadingTimes: true });

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 5) {
        window.sessionStorage.setItem("currentPage", 6);
        window.sessionStorage.setItem("backPage", 5);
        this.getData();
      } else {
        this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
      }
    } else {
      if (parseInt(currentPage) === 5 || parseInt(currentPage) === 6) {
        window.sessionStorage.setItem("currentPage", 6);
        this.getData();
      } else {
        if (window.securedNav === true && (parseInt(currentPage) === 7 || parseInt(currentPage) === 9)) {
          window.sessionStorage.setItem("currentPage", 6);
          window.securedNav = false;
          this.getData();
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) - 1}`);
          } else {
            this.props.history.replace(`/${this.props.match.params.id}/${parseInt(currentPage) - 1}`);
          }

          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          }
        }
      }
    }
  }

  getData() {
    const dow = JSON.parse(window.sessionStorage.getItem("dow"));
    const patientPrefs = JSON.parse(window.sessionStorage.getItem("patientPrefs"));
    let data;

    let providerId = window.sessionStorage.getItem("providerId");

    let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
    let foundProvider = this.props.config.providers[providerIdx];


    if (foundProvider) {
      providerId = foundProvider.provider_id;
    }

    if (window.sessionStorage.getItem("locationId")) {
      data = {
        client: window.sessionStorage.getItem("locationId"),
        dow: dow,
        appointmentId: window.sessionStorage.getItem("appointmentId"),
        providerId: providerId,
        patientPrefs: patientPrefs
      }
    } else {
      data = {
        client: this.props.match.params.id,
        dow: dow,
        appointmentId: window.sessionStorage.getItem("appointmentId"),
        providerId: providerId,
        patientPrefs: patientPrefs
      }
    }

    this.props.getAppointments(data)
      .then(() => {
        let appointments = [];

        if (this.props.appointments !== "NA" && this.props.appointments !== 400) {
          appointments = this.props.appointments;
          appointments = appointments.reverse();
        }

        this.setState({
          loadingTimes: false,
          loaded: true,
          appointments: appointments
        });
      });
  }

  handleProvider(e) {
    e.preventDefault();
    window.sessionStorage.setItem("currentPage", 3);
    window.securedNav = true;
    this.props.history.push(`/${this.props.match.params.id}/3`);
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem("backPage", 6);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/7`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/7`);
    }
  }

  handleAppointment = day => e => {
    e.preventDefault();
    window.sessionStorage.setItem("selectedAppointment", JSON.stringify(day));
    window.sessionStorage.setItem("currentPage", 8);
    window.sessionStorage.setItem("backPage", 6);
    window.sessionStorage.setItem("backButton", JSON.stringify(false));

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/9`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/9`);
    }
  }

  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    const { loadingTimes } = this.state;

    const ContinueButton = styled.button`
      border: 1px solid ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070" } !important;
      }
    `;

    if (!this.state.loaded || loadingTimes) {
      return (
        <div className="apptOptionsContainer">
          <div className="appointmentOptionsContainer">
            <div className="apptHeader">
              Thanks!
            </div>

            <div className="apptDescription">
              Here's what we have available:
            </div>

            <div className="row justify-content-center">
              {loadingTimes && (
                <>
                  <Spacer height="10px" />
                  <PulseLoader color={"#707070"} size={10} />
                </>
              )}
            </div>

            <ContinueButton onClick={this.handleClick} className="moreOptions col col-9 btn btn-primary">More Options</ContinueButton>
          </div>
        </div>
      )
    };

    const appointments = this.state.appointments;

    if (appointments.length < 1) {
      window.sessionStorage.setItem("backPage", 5);

      if (this.props.match.params.analytics !== undefined) {
        this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/7`);
      } else {
        this.props.history.push(`/${this.props.match.params.id}/7`);
      }
    }

    let final = [];

    if (appointments !== 400 && appointments !== "NA" && appointments !== undefined) {
      final = appointments;

      if (final.length > 0) {
        window.sessionStorage.setItem("optionsDow", final[0].dow);
      }
    }

    let changeProvider;
    let provs = [];

    let appointmentIndex;
    let appointment;

    if (this.props.config.appointments) {
      appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.getItem("appointmentId")));
      appointment = this.props.config.appointments[appointmentIndex];
    }

    if (appointment) {
      provs = appointment.providers;

      if (provs.length < 2 || appointment.provider_select === 0) {
        changeProvider = <div></div>
      } else {
        changeProvider = <ContinueButton onClick={this.handleProvider} className="moreOptions col col-9 btn btn-primary">Change Provider</ContinueButton>;
      }
    }

    final = final.slice(0, 3);

    final = final.sort(function(a, b) {
      return a.date.localeCompare(b.date) ||  a.starttime.localeCompare(b.starttime);
    });

    return (
      <div className="apptOptionsContainer" ref={el => { this.el = el; }}>
        <div className="apptHeader">
          Thanks!
        </div>

        <div className="apptDescription">
          Here's what we have available:
        </div>

        <div className="row justify-content-center margin">
          {final.map((day, index) => {
            if (index >= 3) {
              return null;
            } else {
              return (
                <ContinueButton key={index} onClick={this.handleAppointment(day)} className="col col-sm-11 col-md-9 appointmentTime">
                  {moment(day.date).format("dddd, MMMM D")} at {moment(day.starttime, "hh:mm:ss").format("h:mm a").replace(/(a|p)(m)/,'$1.$2.')}
                </ContinueButton>
              )
            }
          })}

          <ContinueButton onClick={this.handleClick} className="appointmentTime col col-sm-11 col-md-9">Show More Options</ContinueButton>
        </div>
        {changeProvider}
      </div>
    )
  }
}

export default AppointmentOptionsOne;

