import React from 'react';
import styled from 'styled-components';
import './first_appointment.scss';
import moment from 'moment';
import { getContrast } from '../../styles/contrast';
import { PulseLoader } from 'react-spinners';

import FlexIcon from '../../styles/flex_icon';

class FirstAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      hover: JSON.parse(window.sessionStorage.getItem("phoneType"))
    }

    this.handleMore = this.handleMore.bind(this);
    this.handleSchedule = this.handleSchedule.bind(this);
    this.getFirstAvailable = this.getFirstAvailable.bind(this);
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = "block";

    const currentPage = window.sessionStorage.getItem("currentPage");

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 3) {
        window.sessionStorage.setItem("currentPage", 4);
        window.sessionStorage.setItem("backPage", 3);
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if (parseInt(currentPage) === 3) {
        window.sessionStorage.setItem("currentPage", 4);
      } else {
        if (window.securedNav === true && (parseInt(currentPage) === 5 || parseInt(currentPage) === 4 || parseInt(currentPage) === 9)) {
          if (parseInt(currentPage) === 4) {
            window.sessionStorage.setItem("currentPage", 3);
          } else {
            window.sessionStorage.setItem("currentPage", 4);
          }
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          }
        }
      }

      window.securedNav = false;
    }

    let providerId = window.sessionStorage.getItem("providerId");

    let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
    let foundProvider = this.props.config.providers[providerIdx];


    if (foundProvider) {
      providerId = foundProvider.provider_id;
    }

    const appointmentId = window.sessionStorage.getItem("appointmentId");

    let data;

    if (window.sessionStorage.getItem("locationId")) {
      data = {
        client: window.sessionStorage.getItem("locationId"),
        appointmentId: appointmentId,
        providerId: providerId
      }
    } else {
      data = {
        client: this.props.match.params.id,
        appointmentId: appointmentId,
        providerId: providerId
      }
    }

    this.getFirstAvailable(data);
  }

  getFirstAvailable(data) {
    this.props.fetchFirstAvailable(data)
      .then(() => {
        this.setState({
          loaded: true
        })
      })
  }

  handleMore(e) {
    e.preventDefault();

    window.sessionStorage.setItem("backPage", 4);
    window.sessionStorage.setItem("currentPage", 4);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/5`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/5`);
    }
  }

  handleSchedule(e) {
    e.preventDefault();

    window.sessionStorage.setItem("selectedAppointment", JSON.stringify(this.props.first_available));
    window.sessionStorage.setItem("currentPage", 8);
    window.sessionStorage.setItem("backButton", JSON.stringify(true));

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/9`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/9`);
    }
  }

  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    if (!this.state.loaded) {
      return  <div className="optionsTwoLoader">
        <Spacer height="10px" />
        <PulseLoader color={"#707070"}  size={10} />
      </div>;
    }


    const Button = styled.button`
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070" } !important;
      }
    `;

    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.getItem("appointmentId")));
    const appointment = this.props.config.appointments[appointmentIndex];

    let providerIndex;
    let provider;

    if (parseInt(window.sessionStorage.getItem("providerId")) !== 0) {
      providerIndex = this.props.config.providers.findIndex(provider => parseInt(provider.provider_assoc) === parseInt(window.sessionStorage.getItem("providerId")));
      provider = this.props.config.providers[providerIndex];

      if (this.props.first_available.dow) {
        if (provider.allowed_days.includes(this.props.first_available.dow)) {
          window.sessionStorage.setItem("provider", JSON.stringify(provider));
        } else {
          providerIndex = this.props.config.providers.findIndex(provider => parseInt(provider.provider_assoc) === parseInt(window.sessionStorage.getItem("providerId")));
          window.sessionStorage.setItem("provider", JSON.stringify(this.props.config.providers[providerIndex]));

          if (parseInt(window.sessionStorage.getItem("backPage")) !== 2) {
            window.sessionStorage.setItem("backPage", 3);
          }

          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/5`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/5`);
          }
        }
      }
    }

    const firstAvailable = this.props.first_available;

    if (!firstAvailable || firstAvailable === 400 || firstAvailable === 404 || firstAvailable === "Invalid message" || firstAvailable === "Invalid date") {
      if (this.props.match.params.analytics !== undefined) {
        this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/5`);
      } else {
        this.props.history.push(`/${this.props.match.params.id}/5`);
      }
    }

    if (Object.entries(firstAvailable).length === 0 && firstAvailable.constructor === Object) {
      if (this.props.match.params.analytics !== undefined) {
        this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/5`);
      } else {
        this.props.history.push(`/${this.props.match.params.id}/5`);
      }
    }

    window.sessionStorage.setItem("firstAvailable", this.props.first_available.date);
    let providerName = "";

    if (appointment.provider_select === 0) {
      providerName = "Our";
    } else {
      providerName = provider.name + "'s";
    }
    return (
      <div className="firstApptContainer row justify-content-center">
        <div className="col col-11 firstAppointmentContainer">

          <div className="firstAppointmentHeader">
            {providerName} first availablity:
          </div>

          <div className="firstAppointmentDescription">
            {moment(firstAvailable.date).format("dddd, MMMM D")} at {moment(firstAvailable.starttime, "hh:mm:ss").format("h:mm a").replace(/(a|p)(m)/,'$1.$2.')}
          </div>

          <FlexIcon class="appointmentTypeImage" icon={11} />

          <div className="firstAppointmentDescriptionTwo">
            Does this work for you?
          </div>

          <Button type="button" className="col col-10 btn btn-outline-primary firstAppointmentSchedule" onClick={this.handleSchedule}>
            Yes, Let's Schedule It!
          </Button>

          <Button type="button" className="col col-10 btn btn-outline-primary firstAppointmentMoreOptions" onClick={this.handleMore}>
            Show Me Other Options
          </Button>
        </div>
      </div>
    )
  }
}

export default FirstAppointment;

