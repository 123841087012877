import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { PulseLoader } from 'react-spinners';
import './appointment_options.scss';
import { getContrast } from '../../styles/contrast';

class AppointmentOptionsTwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loadingTimes: false,
      hover: JSON.parse(window.sessionStorage.getItem("phoneType")),
      appointments: []
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleAppointment = this.handleAppointment.bind(this);
    this.handleProvider = this.handleProvider.bind(this);
  }

  componentDidMount() {
    this.setState({loadingTimes: true});

    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = "block";

    const currentPage = window.sessionStorage.getItem("currentPage");

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 6) {
        window.sessionStorage.setItem("currentPage", 7);
        window.sessionStorage.setItem("backPage", 6);
        this.getData();
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if (parseInt(currentPage) === 6 || parseInt(currentPage) === 7) {
        window.sessionStorage.setItem("currentPage", 7);
        this.getData();
      } else {
        if (window.securedNav === true && (parseInt(currentPage) === 8 || parseInt(currentPage) === 9)) {
          window.sessionStorage.setItem("currentPage", 7);
          window.securedNav = false;
          this.getData();
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          }
        }
      }
    }
  }

  getData() {
    const dow = JSON.parse(window.sessionStorage.getItem("dow"));
    const patientPrefs = JSON.parse(window.sessionStorage.getItem("patientPrefs"));
    let data;

    let providerId = window.sessionStorage.getItem("providerId");

    let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
    let foundProvider = this.props.config.providers[providerIdx];


    if (foundProvider) {
      providerId = foundProvider.provider_id;
    }

    if (window.sessionStorage.getItem("locationId")) {
      data = {
        client: window.sessionStorage.getItem("locationId"),
        dow: dow,
        appointmentId: window.sessionStorage.getItem("appointmentId"),
        providerId: providerId,
        patientPrefs: patientPrefs
      }
    } else {
      data = {
        client: this.props.match.params.id,
        dow: dow,
        appointmentId: window.sessionStorage.getItem("appointmentId"),
        providerId: providerId,
        patientPrefs: patientPrefs
      }
    }

    this.props.getAppointments(data)
      .then(() => {
        const preferences = JSON.parse(window.sessionStorage.getItem("SchedulePreferences"));

        let appointments = this.props.appointments;

        if (this.props.appointments !== "NA" && this.props.appointments !== 400) {
          appointments = appointments.reverse();
        }

        let days = {};

        for (let day in preferences) {
          let keys = [];
          let obj = preferences[day];

          for (let key in obj) {

            if (obj[key] === true) {
              keys.push(key);
              days[day] = keys;
            }
          }
        }

        let daysKeys = Object.keys(days);

        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        let dow = [];

        daysKeys.forEach(day => {
          if (daysOfWeek.indexOf(day)) {
            dow.push(daysOfWeek.indexOf(day) + 1);
          }
        })

        let arg = [];
        let availableAppointments = [];

        if (appointments !== 400 && appointments !== "NA" && typeof appointments !== "string") {
          dow.forEach(day => {
            let appoints = appointments.filter(function(app) {
              return parseInt(app.dow) === parseInt(day);
            });

            if (appoints.length > 1) {
              arg.push(appoints);
            }
          });

          arg.forEach(appt => {
            availableAppointments = availableAppointments.concat(appt);
          })
        }

        if (availableAppointments.length < 4) {
          if (parseInt(window.sessionStorage.getItem("backPage")) !== 5) {
            window.sessionStorage.setItem("backPage", 7);
          }

          window.sessionStorage.setItem("currentPage", 5);

          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/8`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/8`);
          }
        }

        let apps;

        if (window.sessionStorage.getItem("optionsDow")) {
          apps = availableAppointments.filter(function(app) {
            return app.dow !== parseInt(window.sessionStorage.getItem("optionsDow"));
          });
        }

        if (apps) {
          if (apps.length >= 2) {
            availableAppointments = apps;
          }
        }

        let that = this;

        setTimeout(function() {
          that.setState({
            loadingTimes: false,
            loaded: true,
            appointments: appointments
          });
        }, 400);
      })
  }

  handleProvider(e) {
    e.preventDefault();
    window.sessionStorage.setItem("currentPage", 3);
    window.securedNav = true;
    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/3`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/3`);
    }
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem("backPage", 7);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/8`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/8`);
    }
  }

  handleAppointment = day => e => {
    e.preventDefault();

    window.sessionStorage.setItem("selectedAppointment", JSON.stringify(day));
    window.sessionStorage.setItem("currentPage", 8);
    window.sessionStorage.setItem("backPage", 7);
    window.sessionStorage.setItem("backButton", JSON.stringify(false));

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/9`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/9`);
    }
  }

  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    const ContinueButton = styled.button`
      border: 1px solid ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070" } !important;
      }
    `;

    const { loadingTimes } = this.state;

    if (!this.state.loaded || loadingTimes) {
      return (
        <div className="apptOptionsContainer">
          <div className="appointmentOptionsContainer">
            <div className="apptHeader">
              No problem!
            </div>

            <div className="apptDescription">
              Here are some more options:
            </div>

            <div className="row justify-content-center">
              {loadingTimes && (
                <>
                  <Spacer height="10px" />
                  <PulseLoader color={"#707070"} size={10} />
                </>
              )}
            </div>

            <ContinueButton onClick={this.handleClick} className="moreOptions col col-9 btn btn-primary">More Options</ContinueButton>
          </div>
        </div>
      )
    };

    const appointments = this.state.appointments;

    let final = [];

    if (appointments !== 400 && appointments !== "NA" && typeof appointments !== "string") {
      final = appointments;
    }

    let changeProvider;
    let provs = [];


    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.getItem("appointmentId")));
    const appointment = this.props.config.appointments[appointmentIndex];

    if (appointment) {
      provs = appointment.providers;

      if (provs.length < 2 || appointment.provider_select === 0) {
        changeProvider = <div></div>
      } else {
        changeProvider = <ContinueButton onClick={this.handleProvider} className="moreOptions col col-9 btn btn-primary">Change Provider</ContinueButton>;
      }
    }

    final = final.slice(3, 6);

    final = final.sort(function(a, b) {
      return a.date.localeCompare(b.date) ||  a.starttime.localeCompare(b.starttime);
    });

    return (
      <div className="apptOptionsContainer">
        <div className="appointmentOptionsContainer">
          <div className="apptHeader">
            No problem!
          </div>

          <div className="apptDescription">
            Here are some more options:
          </div>

          <div className="row justify-content-center margin">
            {final.map((day, index) => {
              return (
                <ContinueButton key={index} onClick={this.handleAppointment(day)} className="col col-sm-11 col-md-9 appointmentTime">
                  {moment(day.date).format("dddd, MMMM D")} at {moment(day.starttime, "hh:mm:ss").format("h:mm a").replace(/(a|p)(m)/,'$1.$2.')}
                </ContinueButton>
              )
            })}

            <ContinueButton onClick={this.handleClick} className="appointmentTime col col-sm-11 col-md-9">Show More Options</ContinueButton>
          </div>

          {changeProvider}
        </div>
      </div>
    )
  }
}

export default AppointmentOptionsTwo;

